import { createStore } from 'vuex'
import { setToken, getToken } from '@/utils/util';
export default createStore({
  state: {
    token: localStorage.getItem('examToken') || '',
    compareId: [],
    loginModel: false,
    noChance: false,
		activeTab:  window.localStorage.getItem('activeTab') || '',
    isVip:  window.localStorage.getItem('isVip') || false,
    vipTime:  window.localStorage.getItem('vipTime') || '',
    uid:  window.localStorage.getItem('uid') || '',
    isSuperRole:  window.localStorage.getItem('isSuperRole') || false,
    unlockDialog: false,
    tipsDialog: false,
    isKaoYanYear:  window.localStorage.getItem('examKaoYanYear') || '',
    adId:  window.localStorage.getItem('adId') || '',
    isOrganization:  window.localStorage.getItem('isOrganization') || false,
  },
  getters: {
  },
  mutations: {
    setUnlockDialog(state,payload){
      state.unlockDialog = payload
    },
    setTipsDialog(state,payload){
      state.tipsDialog = payload
    },
    setToken(state,payload){
      state.token = payload
    },
    setCompareId(state,payload){
      state.compareId = payload
    },
    setLoginModel(state,payload){
      state.loginModel = payload
    },
    // 限制打开次数
    setChance(state,payload){
      state.noChance = payload
    },
		// 设置激活菜单
		setActiveTab(state, payload) {
			state.activeTab = payload
		},
    // 是否激活
		setIsVip(state, payload) {
			state.isVip = payload
		},
    // 是否可编辑
		setSuperRole(state, payload) {
			state.isSuperRole = payload
		},
    // 是否是机构
		setOrganization(state, payload) {
			state.isOrganization = payload
		},
    // 激活时间
		setVipTime(state, payload) {
			state.vipTime = payload
		},
    // uid
		setUid(state, payload) {
			state.uid = payload
		},
    // 考研年份
		setKaoYanYear(state, payload) {
			state.isKaoYanYear = payload
		},
    // 广告id
		setAdId(state, payload) {
			state.adId = payload
		}
    
  },
  actions: {
  },
  modules: {
  }
})
