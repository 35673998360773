import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../assets/logos.png';
const _withScopeId = n => (_pushScopeId("data-v-d607262a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "agreement"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = ["innerHTML"];
import { ref, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { agreementContent } from "@/api/api";
import { decrypt } from "@/utils/parseDate";
import { useStore } from "vuex";
export default {
  __name: 'agreement',
  setup(__props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const content = ref("");
    const name = ref("");
    // 获取8904-隐私政策 8905-用户协议
    async function getAgreementContent() {
      try {
        const res = await agreementContent(route.query.type);
        if (res.status === 0) {
          name.value = route.query.name;
          content.value = res.result;
        }
      } catch (e) {}
    }
    function goHome() {
      router.push({
        name: "school"
      });
    }
    onMounted(() => {
      getAgreementContent();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "top"
      }, [_createElementVNode("img", {
        class: "logo",
        onClick: goHome,
        style: {
          "cursor": "pointer"
        },
        src: _imports_0
      })]), _createElementVNode("div", _hoisted_2, _toDisplayString(name.value), 1), _createElementVNode("div", {
        class: "content",
        innerHTML: content.value.replace(/\n/g, '<br>')
      }, null, 8, _hoisted_3)]);
    };
  }
};