import request from '@/utils/request'
// 获取是否激活和会员到期时间
export function vipBecomeDueTime(data) {
  return request({
    url: '/grad/school/encrypt/get/person/info',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 获取三级专业集合
export function professionList(data) {
  return request({
    url: '/common/option/adjust/major/option',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 士兵计划和少干计划
export function planList(data) {
  return request({
    url: '/grad/school/encrypt/soldier/school/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 同考试科目学校专业
export function sameSubject(data) {
  return request({
    url: '/grad/school/encrypt/same/subject/school/list',
    method: 'get',
    params: {
      ...data
    }
  })
}

// 获取 8904-隐私政策 8905-用户协议
export function agreementContent(code) {
  return request({
    url: `/app/junior/acquire/dictionary/info?type=${code}`,
    method: 'get'
  })
}

// 获取小程序二维码
export function QRcode() {
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8894',
    method: 'get'
  })
}
// 获取报考年份
export function examineYear() {
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8893',
    method: 'get'
  })
}
// 获取英语字典
export function english() {
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8803',
    method: 'get'
  })
}
// 获取激活引导图片
export function activationVip() {
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8898',
    method: 'get'
  })
}
// 获取学校层次
export function schoolLevel() {
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8801',
    method: 'get'
  })
}
// 获取考研省份
export function province() {
  return request({
    url: '/common/kao/yan/provinces',
    method: 'get'
  })
}
// 获取培养方式和学科门类
export function train() {
  return request({
    url: '/common/get/educate/subject/category',
    method: 'get'
  })
}
// 获取二级分类
export function second(data) {
  return request({
    url: '/common/get/major/one/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 获取学校列表
export function school(data) {
  return request({
    url: '/grad/school/encrypt/school/list',
    method: 'get',
    params: data?{...data}: {}
  })
}
// 根据学校获取学院列表
export function college(data) {
  return request({
    url: '/grad/school/encrypt/college/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 根据学院获取专业
export function major(data) {
	return request({
    url: '/grad/school/encrypt/major/all/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 录取名单
export function useList(data) {
  return request({
    url: '/grad/school/encrypt/pre/year/roll/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 复试名单
export function fushiList(data) {
  return request({
    url: '/grad/school/encrypt/fushi/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 考生分析
export function studentAnalysis(data) {
  return request({
    url: '/grad/school/encrypt/student/analysis',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 复试分析
export function fuluStudentAnalysis(data) {
  return request({
    url: '/grad/school/encrypt/fulu/ratio',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 分数分析
export function scoreAnalysis(data) {
	return request({
    url: '/grad/school/encrypt/enroll/analysis',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 选择本科学校
export function allSchool(data) {
	return request({
    url: '/app/junior/list/all/school',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 获取志愿信息
export function volunteerList(data) {
	return request({
    url: '/grad/school/encrypt/get/volunteer/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 选择本科专业
export function allMajor(data) {
	return request({
    url: '/app/junior/get/major/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 考试分析 新增加入志愿分析
export function volunteerCount(data) {
	return request({
    url: '/grad/school/encrypt/volunteer/count',
    method: 'get',
    params: {
      ...data
    }
  })

}
// 考试范围
export function examRange(data) {
	return request({
    url: '/grad/school/encrypt/exam/range',
    method: 'get',
    params: {
      ...data
    }
  })
}
// - 初试参考书目
export function earlyRefer(data) {
	return request({
    url: '/grad/school/encrypt/early/refer/book',
    method: 'get',
    params: {
      ...data
    }
  })
}
// - 复试参考书目
export function fuRefer(data) {
	return request({
    url: '/grad/school/encrypt/fu/refer/book',
    method: 'get',
    params: {
      ...data
    }
  })
}
// ai择校
export function aiResult(data){
  return request({
    url: '/grad/school/encrypt/filter/school',
    method: 'get',
    params:{
      ...data
    }
  })
}
// 查看学校详情
export function schoolDetail(data){
  return request({
    url: '/grad/school/encrypt/schoolInfo',
    method: 'get',
    params:{
      ...data
    }
  })
}
// 考研真题
export function question(data){
  return request({
    url: '/grad/school/encrypt/major/exam/paper/List',
    method: 'get',
    params:{
      ...data
    }
  })
}
// 获取热点榜
export function hotList(data){
  return request({
    url: '/grad/school/encrypt/major/hot/list',
    method: 'get',
    params:{
      ...data
    }
  })
}
// 获取登录image
export function loginImage(){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8821',
    method: 'get'
  })
}
// 院校专业对比
export function majorCompare(data){
  return request({
    url: '/grad/school/encrypt/get/compare/major',
    method: 'get',
    params:{
      ...data
    }
  })
}
// 删除志愿
export function deleteInfo(data){
  return request({
    url: '/grad/school/encrypt/delete/apply/info',
    method: 'post',
    data
  })
}
// 删除录取名单
export function deleteStudent(data){
  return request({
    url: '/grad/school/encrypt/delete/enroll/student',
    method: 'post',
    data
  })
}
// 添加初试参考书目
export function addEarlyBook(data){
  return request({
    url: '/grad/school/encrypt/add/early/refer/book',
    method: 'post',
    data
  })
}
// 添加复试参考书目
export function addFuBook(data){
  return request({
    url: '/grad/school/encrypt/add/fu/refer/book',
    method: 'post',
    data
  })
}
// 添加录取名单
export function addStudent(data){
  return request({
    url: '/grad/school/encrypt/add/enroll/student',
    method: 'post',
    data
  })
}
// 导入录取名单
export function importStudent(data){
  return request({
    url: '/grad/school/encrypt/import/enroll/student',
    method: 'post',
    data
  })
}
// 订正数据
export function correctMajorInfo(data){
  return request({
    url: '/grad/school/encrypt/correct/major/info',
    method: 'post',
    data
  })
}
// 真题下载
export function download(data){
  return request({
    url: '/grad/school/encrypt/download/send/email',
    method: 'post',
    data
  })
}
// 登陆码登陆
export function login(data){
  return request({
    url: '/grad/school/encrypt/dynamic/code/login',
    method: 'post',
    data
  })
}
// 登陆码登陆
export function newLogin(data){
  return request({
    url: '/grad/school/encrypt/dynamic/code/login/v2',
    method: 'post',
    data
  })
}
// 纠错
export function changeWrong(data){
	return request({
		url: '/grad/school/encrypt/error/recovery',
		method: 'post',
		data
	})
}
// 添加意向专业
export function applyInfo(data){
	return request({
		url: '/grad/school/encrypt/apply/info',
		method: 'post',
		data
	})
}
// 模糊搜索专业
export function searchMajorData(data){
	return request({
		url: '/common/fuzzy/search/major',
		method: 'get',
		params: {
			...data
		}
	})
}
// 根据学校name专业name获取真题数据
export function majorWritingExam(data){
  return request({
    url: '/exam/get/exam/list',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 下载真题
export function downloadUrl(data){
  return request({
    url: '/exam/get/exam/paper/download/url',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 根据学校code获取专业
export function majorBySchool(data){
  return request({
    url: '/exam/get/school/major',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 获取专业库
export function majorLibrary(data){
	return request({
    url: '/grad/school/encrypt/major/library',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 根据专业code获取学校专业
export function schoolByMajor(data){
	return request({
    url: '/grad/school/encrypt/school/major/by/major',
    method: 'get',
    params: {
      ...data
    }
  })
}
// 士兵计划和少干计划下拉列表
export function planListData(){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8803',
    method: 'get'
  })
}
// 下载择校报告
export function downloadReport(data){
  return request({
    url: '/grad/school/encrypt/download/report',
    method: 'post',
    data
  })
}
// 导出报告
export function exportMajorData(data){
  return request({
    url: '/grad/school/encrypt/export/major/data',
    method: 'post',
    data
  })
}
// 获取广告id
export function getAdId(){
	return request({
		url: '/app/junior/acquire/dictionary/info?type=88992'
	})
}
// 联系我们
export function connectUs(){
	return request({
		url: '/app/junior/acquire/dictionary/info?type=8829'
	})
}
// 联系我们弹窗
export function connectUsPopup(){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8833'
  })
}
// 备案号
export function recordNumber(){
	return request({
		url: '/app/junior/acquire/dictionary/info?type=8830'
	})
}
// ai报告限制弹出二维码
export function aiReportLimit(data){
	return request({
		url: '/app/junior/acquire/dictionary/info?type=8831',
		method: 'get',
		data
	})
}
// 获取特殊计划
export function specialPlan(data){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8828',
    method: 'get',
    data
  })
}
// 弹窗状态`
export function popupStatus(data){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8832',
    method: 'get',
    data
  })
}
// 获取一对一链接
export function oneToOneLink(data){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8841',
    method: 'get',
    data
  })
}

// 获取一对一择校
export function getUrlLink(data){
  return request({
    url: '/app/junior/acquire/dictionary/info?type=8903',
    method: 'get',
    data
  })
}
