import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import Home from '../components/home/Home.vue'
import Detail from '@/views/detail/detail.vue'
import Agreement from '@/views/agreement/agreement.vue'
const routes = [
  {
    path: '/',
    component: Home,
    redirect: '/school',
    children:[
			{
				path: 'school',
				name: 'school',
				component: ()=>import('@/views/school/School.vue')
			},
			{
				path:'major',
				name:'major',
				component: ()=>import('@/views/major/Major.vue')
			},
      {
        path: 'ai',
        name: 'ai',
        component: ()=>import('@/views/ai/Ai.vue')
      },
      {
        path: 'found',
        name: 'found',
        component: ()=>import('@/views/found/FoundSchool.vue')
      },
      {
        path: 'profession',
        name: 'profession',
        component: ()=>import('@/views/profession/Profession.vue')
      },
      {
        path: 'plan',
        name: 'plan',
        component: ()=>import('@/views/plan/Plan.vue')
      },
      {
        path: 'compare',
        name: 'compare',
        component: ()=> import('@/views/compare/Compare.vue')
      },
			{
        path: 'hot',
        name: 'hot',
        component: ()=> import('@/views/hot/Hot.vue')
      },
      {
        path: 'exam',
        name: 'exam',
        component: ()=>import('@/views/exam/Exam.vue')
      },
      {
        path: 'workshop',
        name: 'workshop',
        component: ()=>import('@/views/workshop/Workshop.vue')
      }
    ]
  },
  {
    path: '/school/:id/detail',
    // component: ()=>import('@/views/detail/detail.vue')
    component: Detail
  },
  {
    path: '/school/agreement/agreement',
    component: Agreement
  },
  {
    path: '/school/edit/EditDetailPage',
    component: ()=>import('@/views/edit/EditDetailPage.vue')
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
